import { notificationDictionary } from "./notificationDictionary"
import { updateNotificationAsInteracted } from "api/users"
import { getTimeElapsed } from "utils/string"
import classNames from "classnames"
import { trackAction } from "utils/analytics"

import Link from "next/link"
import Image from "next/image"
import LocalImage from "components/shared/LocalImage"

// import Kabob from "icons/kabob.svg"
import Place from "icons/place.svg"

import styles from "./Notification.module.scss"

const Notification = ({ notification, hideNotifications }) => {
  const { data, notification_name, id, created_at, interacted } = notification
  const { company_logo, company_name, companies_logo } = data

  const source = notificationDictionary[notification_name]
  const candidateProfileNames = {
    "Complete Your Profile": "Add a Candidate Profile",
    "Complete Your Profile Reminder": "Add a Candidate Profile Reminder",
  }

  if (!source) {
    return null
  }

  return (
    <Link
      href={source.getLink(data)}
      className={classNames(styles.notification, {
        [styles.notification__uninteracted]: !interacted,
      })}
      onClick={() => {
        trackAction("Notification Clicked", {
          category: source.category,
          notification_name:
            candidateProfileNames?.[notification_name] || notification_name,
          notification_url: source.getLink(data),
        })
        updateNotificationAsInteracted(id)
        if (hideNotifications) {
          hideNotifications(false)
        }
        document.body.click()
      }}
    >
      {!interacted && <div className={styles.dot}></div>}
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapper__overlapping]: companies_logo,
        })}
      >
        {source.image ? (
          <LocalImage src={source.image} alt={source.category} />
        ) : !company_logo ? (
          <Place height="36" width="36" />
        ) : company_logo || companies_logo?.length === 1 ? (
          <Image
            src={companies_logo?.[0] || company_logo}
            fill
            alt={`${company_name}'s logo`}
          />
        ) : companies_logo ? (
          <div className={styles.overlapping}>
            {companies_logo.slice(0, 2).map(logo => (
              <div className={styles.overlapping__image} key={logo}>
                <Image src={logo} fill alt="Company logo" />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className={styles.content}>
        {source.getContent(data)}
        <span className={styles.timestamp}>{`${getTimeElapsed(
          created_at
        )} ago`}</span>
      </div>
      {/* <Kabob height="28" width="28" className={styles.options} /> */}
    </Link>
  )
}

export default Notification
