import { trackAction } from "utils/analytics"
import { CLICKED } from "constants/track-actions"
import Link from "next/link"

import ForwardArrow from "icons/forward-arrow.svg"

import styles from "./AccessExpiredHeader.module.scss"

const AccessExpiredHeader = () => (
  <Link
    href="/rating/step1"
    onClick={() => {
      trackAction(CLICKED, {
        location: "Access Expired Header",
        click_text:
          "Your access has expired. Please add a fresh rating of your current company to continue using RepVue.",
        click_url: `${process.env.NEXT_PUBLIC_URL}/rating/step1`,
        modal_name: null,
      })
    }}
    className={styles.banner}
  >
    <div className={styles.container}>
      <div className={styles.text}>
        <b>Your access has expired.</b>
        <span>
          Please add a fresh rating of your current company to continue using
          RepVue{" "}
        </span>
        <ForwardArrow height="17" width="17" className={styles.arrow} />
      </div>
    </div>
  </Link>
)

export default AccessExpiredHeader
