import pluralize from "pluralize"
import { useDispatch, useSelector } from "react-redux"
import { openSignInModal } from "reducers/auth"
import { trackAction } from "utils/analytics"
import { signOut } from "api/auth"
import { CLICKED } from "constants/track-actions"

import Link from "next/link"
import Button from "components/shared/Button"

import Clock from "icons/clock.svg"

import styles from "./UserSection.module.scss"

const UserSection = ({ closeMenu }) => {
  const dispatch = useDispatch()
  const { authenticated, loading, currentUser } = useSelector(
    state => state.auth
  )

  const { days_until_next_rating } = currentUser
  const displayDays =
    days_until_next_rating < 300 ? days_until_next_rating : "Unlimited"

  const handleSignInOpen = () => {
    dispatch(openSignInModal())
    trackAction(CLICKED, {
      location: "Navbar",
      click_text: "Sign In",
      click_url: null,
      modal_name: "Sign Into Your Account",
    })
  }

  const handleSignOut = () => {
    trackAction(CLICKED, {
      location: "Navbar",
      click_text: "Sign Out",
      click_url: null,
      modal_name: null,
    })
    dispatch(signOut())
  }

  return (
    <>
      <section className={styles.userSection}>
        <article className={styles.userSection__title}>
          <span>Account</span>
        </article>

        <nav className={styles.userSection__menu}>
          <ul>
            {authenticated && (
              <>
                <li>
                  <Link href="/user/dashboard" onClick={() => closeMenu(false)}>
                    My Profile
                  </Link>
                </li>
                <li>
                  <button onClick={handleSignOut} id="sign-out-link">
                    Sign Out
                  </button>
                </li>
              </>
            )}

            {!!!authenticated && !!!loading && (
              <li>
                <button onClick={handleSignInOpen} id="sign-in-link-mobile">
                  Sign In
                </button>
              </li>
            )}
          </ul>
        </nav>
      </section>

      <section className={styles.userSection__actions}>
        <Link
          href={authenticated ? "/rating/step1" : "/rating/step1?sign_up=true"}
        >
          <Button
            size="large"
            variant="primary"
            onClick={() => {
              trackAction(CLICKED, {
                location: "Navbar",
                click_text: "Add a Rating",
                click_url: `${process.env.NEXT_PUBLIC_URL}/rating/step1`,
                modal_name: null,
              })
            }}
          >
            Add a Rating
          </Button>
        </Link>

        {authenticated && (
          <span className={styles.userSection__actions__days}>
            <Clock
              className={styles.userSection__actions__icon}
              height="16"
              width="16"
            />
            {`${displayDays} ${pluralize(
              "days",
              days_until_next_rating,
              false
            )}`}{" "}
            until your next rating is due
          </span>
        )}
      </section>
    </>
  )
}

export default UserSection
