import Link from "next/link"
import Settings from "icons/settings.svg"
import Close from "icons/close.svg"
import Notification from "./Notification"
import Spinner from "react-bootstrap/Spinner"
import { notificationDictionary } from "./notificationDictionary"

import styles from "./UserNotifications.module.scss"

const UserNotifications = ({ notifications, loading, hideNotifications }) => {
  const { notification_events } = notifications
  const availableNotifications =
    notification_events?.filter(notification =>
      Object.keys(notificationDictionary).includes(
        notification.notification_name
      )
    ) || []

  return (
    <div className={styles.notifications}>
      <div className={styles.header}>
        <h6>Notifications</h6>
        <Link
          href="/user/settings"
          aria-label="settings"
          onClick={() => document.body.click()}
        >
          <span>
            <Settings height="20" width="20" />
            Settings
          </span>
        </Link>
        <Close
          height="25"
          width="25"
          className={styles.close}
          onClick={hideNotifications}
        />
      </div>
      {availableNotifications?.length > 0 ? (
        <>
          <div className={styles.body}>
            {notification_events &&
              availableNotifications.map((notification, i) => (
                <Notification
                  key={i}
                  notification={notification}
                  hideNotifications={hideNotifications}
                />
              ))}
          </div>
          <div className={styles.footer}>
            <Link
              href="/user/notifications"
              onClick={() => {
                hideNotifications()
                document.body.click()
              }}
            >
              View All Notifications
            </Link>
          </div>
        </>
      ) : !loading ? (
        <div className={styles.empty}>
          <span className={styles.empty__headline}>No notifications</span>
          <p className={styles.empty__body}>
            It looks like you&apos;re all caught up on notifications. As new
            updates become available, they&apos;ll appear here in your
            Notifications tab.
          </p>
        </div>
      ) : (
        <div className={styles.spinner}>
          <Spinner animation="border" variant="dark" />
        </div>
      )}
    </div>
  )
}

export default UserNotifications
