import Link from "next/link"
import UserSection from "../UserSection"
import BetaTag from "components/shared/BetaTag"
import ConditionalWrapper from "components/shared/Wrappers/ConditionalWrapper"
import NewTag from "components/tags/New"

import BackArrow from "icons/back-arrow.svg"
import ChevronRight from "icons/chevron-right.svg"

import styles from "./SectionMenu.module.scss"

const SectionMenu = ({
  itemsMenu = [],
  changeSection,
  currentSection,
  parent,
  menuSelect,
  showUser = false,
  closeMenu,
  routeCategory,
}) => {
  return (
    <section className={styles.sectionMenu}>
      {parent && (
        <article
          className={styles.sectionMenu__headerBack}
          onClick={() => changeSection(currentSection - 1, parent)}
        >
          <BackArrow height="16" width="16" />
          <span>{parent}</span>
        </article>
      )}

      {menuSelect && (
        <article className={styles.sectionMenu__title}>
          <ConditionalWrapper
            condition={routeCategory}
            ifTrue={children => (
              <Link href={`/${routeCategory}`} onClick={() => closeMenu(false)}>
                {children}
              </Link>
            )}
            ifFalse={children => <>{children}</>}
          >
            <span>{menuSelect}</span>
          </ConditionalWrapper>
        </article>
      )}

      <nav className={styles.sectionMenu__menu}>
        <ul>
          {itemsMenu?.map((menu, key) => {
            if (!menu) return
            return (
              <li key={menu.title}>
                {menu?.slug && (
                  <Link
                    href={`/${menu?.route || ""}${menu.slug}`}
                    onClick={() => closeMenu(false)}
                  >
                    {menu?.title}
                    {menu?.isNew && <NewTag />}
                    {menu?.beta && <BetaTag />}
                  </Link>
                )}{" "}
                {!menu?.slug && (
                  <span
                    onClick={() =>
                      changeSection(currentSection + 1, menu.title)
                    }
                  >
                    {" "}
                    {menu?.title}
                    {menu?.isNew && <NewTag />}
                    <ChevronRight
                      className={styles.sectionMenu__menu__icon}
                      height="13"
                      width="13"
                    />
                  </span>
                )}
              </li>
            )
          })}
        </ul>
      </nav>

      {showUser && <UserSection closeMenu={closeMenu} />}
    </section>
  )
}

export default SectionMenu
