import { useState } from "react"
import { trackAction } from "utils/analytics"
import { CLICKED } from "constants/track-actions"
import { useDispatch } from "react-redux"
import { openSignInModal } from "reducers/auth"
import { getAvatar } from "utils/icons"

import { Popover, OverlayTrigger } from "react-bootstrap"
import Link from "next/link"
import cn from "classnames"
import Button from "components/shared/Button"
import NavbarPopover from "components/Navbar/NavbarPopover"
import NotificationsToggle from "components/Navbar/NavNotifications/NotificationsToggle"

import Chevron from "icons/chevron.svg"

import styles from "./authSection.module.scss"

const AuthSection = ({ authenticated, loading, currentUser }) => {
  const [showPopover, setShowPopover] = useState(false)

  const { days_until_next_rating, color } = currentUser
  const daysCountdown = ((120 - days_until_next_rating) / 120) * 100

  const dispatch = useDispatch()

  const handleSignInOpen = () => {
    dispatch(openSignInModal())
    trackAction(CLICKED, {
      location: "Navbar",
      click_text: "Sign In",
      click_url: null,
      modal_name: "Sign Into Your Account",
    })
  }

  const popover = props => (
    <Popover id="popover-basic" {...props}>
      <NavbarPopover
        days_until_next_rating={days_until_next_rating}
        daysCountdown={daysCountdown}
      />
    </Popover>
  )

  return (
    <div className={styles.authWrapper}>
      <ul className={styles.submenuSecondary}>
        {authenticated && !loading && (
          <li>
            <div className={styles.profileBar}>
              <NotificationsToggle />
              <OverlayTrigger
                trigger="click"
                rootClose
                onExit={() => setShowPopover(false)}
                placement="bottom"
                overlay={popover}
              >
                <div
                  className={cn({
                    [`${styles.profileBar__dropdown}`]: true,
                    [`${styles.profileBar__active}`]: showPopover,
                  })}
                  onClick={() => setShowPopover(!showPopover)}
                >
                  {getAvatar(color, 27, styles.profileBar__avatar)}
                  <Chevron
                    width={20}
                    height={20}
                    className={styles.profileBar__chevron}
                  />
                </div>
              </OverlayTrigger>
            </div>
            {authenticated && (
              <div className={styles.mobileProfileBar}>
                <Link
                  href="/user/dashboard"
                  className={`${styles.link} nav-link ${styles.mobileProfileBar__signIn}`}
                >
                  My Profile
                </Link>
              </div>
            )}
          </li>
        )}
        {!authenticated && !loading && (
          <li>
            <button
              onClick={handleSignInOpen}
              className={`${styles.link} nav-link btn btn-link`}
              id="sign-in-link"
            >
              Sign In
            </button>
          </li>
        )}
      </ul>
      <div className={styles.rating}>
        <Link
          href={authenticated ? "/rating/step1" : "/rating/step1?sign_up=true"}
        >
          <Button
            size="medium"
            variant="primary"
            onClick={() => {
              trackAction(CLICKED, {
                location: "Navbar",
                click_text: "Add a Rating",
                click_url: `${process.env.NEXT_PUBLIC_URL}/rating/step1`,
                modal_name: null,
              })
            }}
          >
            Add a Rating
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default AuthSection
