/* eslint-disable sonarjs/no-duplicate-string */

export const countryMap = {
  AU: {
    currency: "AUD",
    flag: "&#127462;&#127482;",
    name: "Australia",
    currencySymbol: "A$",
    toggleSymbol: "$",
  },
  BE: {
    currency: "EUR",
    flag: "&#127463;&#127466;",
    name: "Belgium",
    currencySymbol: "€",
  },
  BR: {
    currency: "BRL",
    flag: "&#127463;&#127479;",
    name: "Brazil",
    currencySymbol: "R$",
    toggleSymbol: "$",
  },
  CA: {
    currency: "CAD",
    flag: "&#127464;&#127462;",
    name: "Canada",
    currencySymbol: "CA$",
    toggleSymbol: "$",
  },
  DE: {
    currency: "EUR",
    flag: "&#127465;&#127466;",
    name: "Germany",
    currencySymbol: "€",
  },
  ES: {
    currency: "EUR",
    flag: "&#127466;&#127480;",
    name: "Spain",
    currencySymbol: "€",
  },
  FI: {
    currency: "EUR",
    flag: "&#127467;&#127470;",
    name: "Finland",
    currencySymbol: "€",
  },
  FR: {
    currency: "EUR",
    flag: "&#127467;&#127479;",
    name: "France",
    currencySymbol: "€",
  },
  GB: {
    currency: "GBP",
    flag: "&#127468;&#127463;",
    name: "UK",
    currencySymbol: "£",
  },
  IE: {
    currency: "EUR",
    flag: "&#127470;&#127466;",
    name: "Ireland",
    currencySymbol: "€",
  },
  IL: {
    currency: "ILS",
    flag: "&#127470;&#127473;",
    name: "Israel",
    currencySymbol: "₪",
  },
  IN: {
    currency: "INR",
    flag: "&#127470;&#127475;",
    name: "India",
    currencySymbol: "₹",
  },
  IT: {
    currency: "EUR",
    flag: "&#127470;&#127481;",
    name: "Italy",
    currencySymbol: "€",
  },
  MX: {
    currency: "MXN",
    flag: "&#127474;&#127485;",
    name: "Mexico",
    currencySymbol: "MX$",
  },
  NL: {
    currency: "EUR",
    flag: "&#127475;&#127473;",
    name: "The Netherlands",
    currencySymbol: "€",
  },
  PT: {
    currency: "EUR",
    flag: "&#127477;&#127481;",
    name: "Portugal",
    currencySymbol: "€",
  },
  SE: {
    currency: "SEK",
    flag: "&#127466;&#127480;",
    name: "Sweden",
    currencySymbol: "kr",
  },
  SG: {
    currency: "SGD",
    flag: "&#127480;&#127468;",
    name: "Singapore",
    currencySymbol: "SGD ",
    toggleSymbol: "",
  },
  US: {
    currency: "USD",
    flag: "&#127482;&#127480;",
    name: "United States",
    currencySymbol: "$",
  },
}

export const countryRoles = {
  AU: [
    "account-executive",
    "account-manager",
    "customer-success-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
    "sales-engineer",
    "smb-account-executive",
    "strategic-account-executive",
  ],
  BE: ["account-executive"],
  BR: ["account-executive"],
  CA: [
    "account-executive",
    "account-manager",
    "customer-success-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
    "sales-engineer",
    "sled-account-executive",
    "smb-account-executive",
  ],
  FR: [
    "account-executive",
    "account-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
  ],
  DE: [
    "account-executive",
    "account-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
    "sales-engineer",
    "smb-account-executive",
  ],
  IN: [
    "account-executive",
    "account-manager",
    "customer-success-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
    "smb-account-executive",
  ],
  IE: [
    "account-executive",
    "account-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
    "smb-account-executive",
  ],
  IL: ["account-executive", "sales-development-representative"],
  IT: ["account-executive"],
  NL: [
    "account-executive",
    "account-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
    "smb-account-executive",
  ],
  SG: [
    "account-executive",
    "account-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
    "smb-account-executive",
  ],
  ES: [
    "account-executive",
    "account-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-representative",
    "smb-account-executive",
  ],
  SE: ["account-executive"],
  GB: [
    "account-executive",
    "account-manager",
    "customer-success-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-manager",
    "sales-development-representative",
    "smb-account-executive",
    "strategic-account-executive",
  ],
  US: [
    "account-executive",
    "account-manager",
    "customer-success-manager",
    "enterprise-account-executive",
    "mid-market-account-executive",
    "sales-development-manager",
    "sales-development-representative",
    "sales-engineer",
    "sales-manager",
    "sled-account-executive",
    "smb-account-executive",
    "strategic-account-executive",
  ],
}

export const codeArray = Object.keys(countryRoles)
