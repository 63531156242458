/* eslint-disable sonarjs/no-duplicate-string */
import { rolesEnumerated } from "utils/common"
import { countryMap } from "utils/countries"

export const getNavMobileMenu = (countryRoles = {}, codeArray = []) => {
  const mobileNavMenu = {
    "section-0": {
      "Main Menu": {
        items: [
          {
            title: "Companies",
          },
          {
            title: "Sales Jobs",
          },
          {
            title: "Salaries",
          },
          {
            title: "Community",
            slug: "community-insights",
            beta: true,
          },
          {
            title: "For Employers",
            slug: "employers",
          },
        ],
      },
    },

    "section-1": {
      Companies: {
        parent: "Main Menu",
        route: "companies",
        items: [
          {
            title: "Explore Companies",
            slug: "companies",
          },
          {
            title: "Rankings",
            slug: "rankings",
          },
          {
            title: "Compare Companies",
            slug: "companies/compare/Salesforce-vs-Snowflake",
            isNew: false,
          },
          {
            title: "Quarterly Report",
            slug: "cloud-index/2024/Q2",
            isNew: true,
          },
          // {
          //   title: "Companies by Industry",
          // },
        ],
      },
      "Sales Jobs": {
        parent: "Main Menu",
        route: "sales-jobs",
        items: [
          {
            title: "SDR / BDR",
            slug: "by-category/sdr-bdr",
            route: "sales-jobs/",
          },
          {
            title: "Account Executive",
            slug: "by-category/account-executive",
            route: "sales-jobs/",
          },
          {
            title: "Account Manager",
            slug: "by-category/account-manager",
            route: "sales-jobs/",
          },
          {
            title: "Customer Success",
            slug: "by-category/customer-success",
            route: "sales-jobs/",
          },
          {
            title: "Sales Engineer",
            slug: "by-category/sales-engineer",
            route: "sales-jobs/",
          },
          {
            title: "Remote Sales Jobs",
            slug: "by-category/remote",
            route: "sales-jobs/",
          },
        ],
      },
      Salaries: {
        parent: "Main Menu",
        route: "salaries",
        items: [
          {
            title: "Medical Sales Salaries",
            isNew: true,
          },
          {
            title: "Salaries by Job Role",
          },
          {
            title: "AE Salaries by City",
          },
          {
            title: "SDR Salaries by City",
          },
          {
            title: "International Salaries",
          },
          {
            title: "Salary Calculator",
            slug: "salary-calculator",
          },
        ],
      },

      Community: {
        parent: "Main Menu",
        route: "community-insights",
      },
    },
    "section-2": {
      "Medical Sales Salaries": {
        parent: "Salaries",
        route: "salaries",
        items: [
          {
            title: "Medical Device Sales Representative",
            route: "salaries/",
            slug: "medical-device-sales-representative",
          },
        ],
      },
      "Salaries by Job Role": {
        parent: "Salaries",
        route: "salaries",
        items: [
          {
            title: "Account Executive",
          },
          { title: "Sales Management" },
          {
            title: "Account Manager",
            slug: "account-manager",
            route: "salaries/",
          },
          {
            title: "Customer Success",
            slug: "customer-success-manager",
            route: "salaries/",
          },
          {
            title: "Sales Development Representative",
            slug: "sales-development-representative",
            route: "salaries/",
          },
          {
            title: "Sales Engineer",
            slug: "sales-engineer",
            route: "salaries/",
          },
        ],
      },
      "AE Salaries by City": {
        parent: "Salaries",
        route: "salaries",
        items: [
          {
            title: "Atlanta, GA",
            route: "salaries/",
            slug: "account-executive/atlanta-ga",
          },
          {
            title: "Austin, TX",
            route: "salaries/",
            slug: "account-executive/austin-round-rock-tx",
          },
          {
            title: "Boston, MA",
            route: "salaries/",
            slug: "account-executive/boston-providence-ma-ri-nh-ct",
          },
          {
            title: "Chicago, IL",
            route: "salaries/",
            slug: "account-executive/chicago-il",
          },
          {
            title: "Los Angeles, CA",
            route: "salaries/",
            slug: "account-executive/los-angeles-anaheim-ca",
          },
          {
            title: "San Francisco, CA",
            route: "salaries/",
            slug: "account-executive/san-francisco-oakland-ca",
          },
          {
            title: "San Jose, CA",
            route: "salaries/",
            slug: "account-executive/san-jose-silicon-valley-ca",
          },
          {
            title: "New York, NY",
            route: "salaries/",
            slug: "account-executive/greater-new-york-ny-nj-ct",
          },
          {
            title: "Seattle, WA",
            route: "salaries/",
            slug: "account-executive/seattle-tacoma-wa",
          },
        ],
      },
      "SDR Salaries by City": {
        parent: "Salaries",
        route: "salaries",
        items: [
          {
            title: "Atlanta, GA",
            route: "salaries/",
            slug: "sales-development-representative/atlanta-ga",
          },
          {
            title: "Austin, TX",
            route: "salaries/",
            slug: "sales-development-representative/austin-round-rock-tx",
          },
          {
            title: "Boston, MA",
            route: "salaries/",
            slug: "sales-development-representative/boston-providence-ma-ri-nh-ct",
          },
          {
            title: "Chicago, IL",
            route: "salaries/",
            slug: "sales-development-representative/chicago-il",
          },
          {
            title: "Los Angeles, CA",
            route: "salaries/",
            slug: "sales-development-representative/los-angeles-anaheim-ca",
          },
          {
            title: "San Francisco, CA",
            route: "salaries/",
            slug: "sales-development-representative/san-francisco-oakland-ca",
          },
          {
            title: "San Jose, CA",
            route: "salaries/",
            slug: "sales-development-representative/san-jose-silicon-valley-ca",
          },
          {
            title: "New York, NY",
            route: "salaries/",
            slug: "sales-development-representative/greater-new-york-ny-nj-ct",
          },
          {
            title: "Seattle, WA",
            route: "salaries/",
            slug: "sales-development-representative/seattle-tacoma-wa",
          },
        ],
      },
      "International Salaries": {
        parent: "Salaries",
        route: "salaries",
        items: codeArray.map(countryCode => {
          if (countryCode === "US") return null
          return {
            title: countryMap[countryCode]?.name,
          }
        }),
      },
      // "Companies by Industry": {
      //   parent: "Companies",
      //   route: "companies",
      //   items: industries.map(industry => ({
      //     title: industry.name,
      //     slug: industry.slug,
      //     route: "companies/industries/",
      //   })),
      // },
    },
    "section-3": {
      "Account Executive": {
        parent: "Salaries by Job Role",
        route: "salaries/account-executive",
        items: [
          {
            title: "Strategic Account Executive",
            slug: "strategic-account-executive",
            route: "salaries/",
          },
          {
            title: "Enterprise Account Executive",
            slug: "enterprise-account-executive",
            route: "salaries/",
          },
          {
            title: "Mid Market Account Executive",
            slug: "mid-market-account-executive",
            route: "salaries/",
          },
          {
            title: "SLED Account Executive",
            slug: "sled-account-executive",
            route: "salaries/",
          },
          {
            title: "SMB Account Executive",
            slug: "smb-account-executive",
            route: "salaries/",
          },
        ],
      },
      "Sales Management": {
        parent: "Salaries by Job Role",
        items: [
          {
            title: "Sales Manager",
            slug: "sales-manager",
            route: "salaries/",
          },
          {
            title: "Sales Development Manager",
            slug: "sales-development-manager",
            route: "salaries/",
          },
        ],
      },
    },
  }

  codeArray.forEach(countryCode => {
    mobileNavMenu["section-3"][`${countryMap[countryCode]?.name}`] = {
      parent: "International Salaries",
      items: countryRoles?.[countryCode]?.map(role => ({
        title: `${rolesEnumerated[role]}`,
        slug: `${role}/${countryCode}`,
        route: "salaries/",
      })),
    }
  })

  return mobileNavMenu
}

export const NAV_MOBILE_SECTIONS = [
  { id: 0, containerClass: "mobileNavbar__container__one" },
  { id: 1, containerClass: "mobileNavbar__container__two" },
  { id: 2, containerClass: "mobileNavbar__container__three" },
  { id: 3, containerClass: "mobileNavbar__container__one" },
]
