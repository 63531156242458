import apiCall from "utils/api"

export const getQuestions = (pageNumber, params) => {
  const options = { params }
  return apiCall("get", `questions?page=${pageNumber}&per_page=20`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const submitQuestion = (question, slug) => {
  const options = {
    data: {
      question: {
        body: question,
      },
    },
  }
  return apiCall("post", `companies/${slug}/questions`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const submitReply = (reply, companySlug, questionSlug, answerId) => {
  const options = {
    data: {
      answer: {
        body: reply,
        parent_answer_id: answerId,
      },
    },
  }
  return apiCall(
    "post",
    `companies/${companySlug}/questions/${questionSlug}/answers`,
    options
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const submitAnswer = (answer, companySlug, questionSlug) => {
  const options = {
    data: {
      answer: {
        body: answer,
      },
    },
  }
  return apiCall(
    "post",
    `companies/${companySlug}/questions/${questionSlug}/answers`,
    options
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const submitAnswerVote = async (vote, id) => {
  const options = {
    data: {
      value: vote,
    },
  }
  return apiCall("post", `answers/${id}/vote`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const submitQuestionVote = async (vote, id) => {
  const options = {
    data: {
      value: vote,
    },
  }
  return apiCall("post", `questions/${id}/vote`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
