import { useState, useEffect } from "react"
import cn from "classnames"
import { getNavMobileMenu, NAV_MOBILE_SECTIONS } from "constants/navbar"
import { codeArray, countryRoles } from "utils/countries"
import Close from "icons/close.svg"
import Logo from "components/logo"
import SectionMenu from "./SectionMenu"

import styles from "./MobileNavbar.module.scss"

const MobileNavbar = ({ closeMenu, open = false }) => {
  const [currentSection, setCurrentSection] = useState(0)
  const [menuSelect, setMenuSelect] = useState("Main Menu")
  // const [industries, setIndustries] = useState([])

  const changeSection = (section, menu) => {
    if (menu) {
      setMenuSelect(menu)
      setCurrentSection(section)
    }
    setCurrentSection(section)
  }

  const navMobileMenu = getNavMobileMenu(countryRoles, codeArray)

  useEffect(() => {
    if (!open) {
      setMenuSelect("Main Menu")
      setCurrentSection(0)
    }
  }, [open])

  // useEffect(() => {
  //   getIndustries()
  //     .then(res => setIndustries(res.data))
  //     .catch(error => console.error(error))
  // }, [])

  return (
    <section
      className={cn(styles.mobileNavbar, { [styles.mobileNavbar__open]: open })}
    >
      <article className={styles.mobileNavbar__header}>
        <Logo />
        <Close height="30" width="30" onClick={() => closeMenu(false)} />
      </article>

      {NAV_MOBILE_SECTIONS.map(section => (
        <article
          key={section.id}
          className={cn(
            styles.mobileNavbar__container,
            styles[section.containerClass],
            {
              [styles.mobileNavbar__container__left]:
                currentSection > section.id,
              [styles.mobileNavbar__container__center]:
                currentSection === section.id,
              [styles.mobileNavbar__container__right]:
                currentSection < section.id,
            }
          )}
        >
          <SectionMenu
            itemsMenu={
              navMobileMenu[`section-${section.id}`][menuSelect]?.items
            }
            changeSection={changeSection}
            currentSection={currentSection}
            menuSelect={section?.id !== 0 ? menuSelect : null}
            parent={navMobileMenu[`section-${section.id}`][menuSelect]?.parent}
            routeCategory={
              navMobileMenu[`section-${section.id}`][menuSelect]?.route
            }
            closeMenu={closeMenu}
            showUser={section?.id === 0}
          />
        </article>
      ))}
    </section>
  )
}

export default MobileNavbar
