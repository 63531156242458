"use client"
import { useState, useEffect, useRef } from "react"
import { usePathname } from "next/navigation"
import cn from "classnames"

import Link from "next/link"
import Dropdown from "components/Navbar/DesktopNavbar/Dropdown"
import BetaTag from "components/shared/BetaTag"
import NewTag from "components/tags/New"

import ChevronRight from "icons/chevron-right.svg"

import styles from "./menuItems.module.scss"

const MenuItems = ({
  items,
  depthLevel,
  submenuItems,
  setSubMenuItems,
  index,
}) => {
  const pathname = usePathname()
  const [dropdown, setDropdown] = useState(false)
  const [isActive, setIsActive] = useState(false)

  let ref = useRef()

  useEffect(() => {
    const handler = event => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false)
      }
    }
    document.addEventListener("mousedown", handler)
    document.addEventListener("touchstart", handler)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler)
      document.removeEventListener("touchstart", handler)
    }
  }, [dropdown])

  const onMouseEnter = items => {
    if (items?.length) {
      setSubMenuItems(item => (item < items?.length ? items?.length : item))
    }
    setDropdown(true)
  }

  const onMouseLeave = () => {
    setDropdown(false)
    if (depthLevel === 0) setSubMenuItems(0)
  }

  const closeDropdown = () => {
    dropdown && setDropdown(false)
  }

  const getActive = slug => {
    setIsActive(false)
    if (slug === pathname) setIsActive(true)
  }

  useEffect(() => {
    getActive(items?.slug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  if (!items) return

  return (
    <li
      className={cn(styles.menuItems, {
        [styles.menuItems__main]: depthLevel === 0,
      })}
      ref={ref}
      onMouseEnter={() => onMouseEnter(items?.submenu)}
      onMouseLeave={() => onMouseLeave()}
      onClick={closeDropdown}
    >
      {items?.slug && items?.submenu ? (
        <>
          <div onClick={() => setDropdown(prev => !prev)}>
            {depthLevel === 0 ? (
              <div
                className={cn(styles.menuItems__main__link, {
                  [styles.menuItems__main__active]: isActive,
                })}
              >
                <Link href={items?.slug}>
                  {items?.title}
                  <ChevronRight
                    height="13"
                    width="13"
                    className={styles.menuItems__chevronDown}
                  />
                </Link>
              </div>
            ) : (
              <Link href={items?.slug}>
                {items?.title}
                {items?.isNew && <NewTag />}
                <ChevronRight
                  height="13"
                  width="13"
                  className={styles.menuItems__chevronRight}
                />
              </Link>
            )}
          </div>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items?.submenu}
            dropdown={dropdown}
            label={items?.label}
            numberItems={submenuItems}
            submenuItems={submenuItems}
            setSubMenuItems={setSubMenuItems}
            index={index}
            parentLabel={items?.parentLabel}
            topLevel={items?.topLevel}
          />
        </>
      ) : !items?.slug && items?.submenu ? (
        <>
          <div onClick={() => setDropdown(prev => !prev)}>
            {items?.title} {items?.slug}
            {depthLevel > 0 ? (
              <span>&raquo;</span>
            ) : (
              <ChevronRight height="13" width="13" />
            )}
          </div>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items?.submenu}
            dropdown={dropdown}
            label={items?.label}
            numberItems={submenuItems}
            submenuItems={submenuItems}
            setSubMenuItems={setSubMenuItems}
            index={index}
            parentLabel={items?.parentLabel}
            topLevel={items?.topLevel}
          />
        </>
      ) : (
        <>
          {depthLevel === 0 ? (
            <div
              className={cn(styles.menuItems__main__link, {
                [styles.menuItems__main__active]: isActive,
              })}
            >
              <Link href={items?.slug}>
                {items?.title} {items?.isNew && <NewTag />}
                {items?.beta && <BetaTag />}
              </Link>
            </div>
          ) : (
            <Link href={items?.slug}>
              {items?.title}
              {items?.isNew && <NewTag />}
              {items?.beta && (
                <span className={styles.menuItems__betaTag}>Beta</span>
              )}
            </Link>
          )}
        </>
      )}
    </li>
  )
}

export default MenuItems
