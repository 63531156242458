import cn from "classnames"
import MenuItems from "components/Navbar/DesktopNavbar/MenuItems"
import styles from "./dropDown.module.scss"

const Dropdown = ({
  submenus,
  dropdown,
  depthLevel,
  label,
  numberItems,
  submenuItems,
  setSubMenuItems,
  index = 0,
  parentLabel,
  topLevel,
}) => {
  depthLevel = depthLevel + 1

  const STYLES = {
    1: styles.dropDown__1,
    2: styles.dropDown__2,
    3: styles.dropDown__3,
    4: styles.dropDown__4,
  }

  return (
    <div
      className={cn(STYLES[index], {
        [styles.dropDown__main]: depthLevel === 1,
      })}
    >
      <ul
        className={cn(styles.dropDown, {
          [styles.dropDown__submenu]: depthLevel > 1,
          [styles.dropDown__show]: dropdown,
          [styles.dropDown__decoration]: depthLevel === 1,
        })}
      >
        <div
          className={cn(styles.dropDown__container, {
            [styles.dropDown__container__submenu]: depthLevel > 1,
          })}
        >
          <div
            className={cn(styles.dropDown__label, {
              [styles.dropDown__label__parent]: parentLabel,
            })}
          >
            <span>{label}</span>
          </div>
          <div
            className={cn(styles.dropDown__itemsContainer, {
              [styles.dropDown__itemsContainer__border]: depthLevel > 1,
              [styles.dropDown__itemsContainer__fixedHeight]:
                submenus?.length > 6 || numberItems > 6,
              [styles.dropDown__itemsContainer__scroll]:
                (submenus?.length > 6 || numberItems > 6) && !topLevel,
            })}
            style={{ height: `${numberItems * 37}px` }}
          >
            {submenus?.map((submenu, index) => (
              <MenuItems
                items={submenu}
                key={index}
                depthLevel={depthLevel}
                submenuItems={submenuItems}
                setSubMenuItems={setSubMenuItems}
              />
            ))}
          </div>
        </div>
      </ul>
    </div>
  )
}

export default Dropdown
