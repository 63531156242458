import classNames from "classnames"
import { useSelector } from "react-redux"
import { useRouter } from "next/navigation"

import Bell from "icons/bell.svg"

import styles from "./NotificationsToggle.module.scss"

const MobileNotificationsToggle = ({
  showNotifications,
  handleNotifications,
}) => {
  const { currentUser } = useSelector(state => state.auth)
  const { unread_notifications_count } = currentUser

  const router = useRouter()

  const { route } = router

  const isNotifications = route === "/user/notifications"

  return (
    <div
      className={classNames(styles.wrapper, styles.wrapper__dropdown, {
        [styles.wrapper__active]: showNotifications,
      })}
      onClick={handleNotifications}
    >
      <Bell height="24" width="24" />
      {unread_notifications_count > 0 && !isNotifications && (
        <div className={styles.count}>{unread_notifications_count}</div>
      )}
    </div>
  )
}

export default MobileNotificationsToggle
