"use client"

import { useState, useEffect } from "react"

export const useValidateMobile = (width = 992) => {
  const [isMobile, setIsMobile] = useState(undefined)

  useEffect(() => {
    if (window?.innerWidth < width) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    const MEDIA_QUERY = window?.matchMedia(`(max-width: ${width}px)`)
    MEDIA_QUERY.addEventListener("change", event => {
      setIsMobile(event?.matches)
    })
  }, [width])

  return { isMobile }
}
