import { useState, useRef } from "react"
import classNames from "classnames"
import { updateNotificationsAsRead, getNotifications } from "api/users"
import { setCurrentUser } from "reducers/auth"
import { useSelector, useDispatch } from "react-redux"
import { OverlayTrigger, Popover } from "react-bootstrap"

import UserNotifications from "components/shared/Notifications/UserNotifications"

import Bell from "icons/bell.svg"

import styles from "./NotificationsToggle.module.scss"

const NotificationsToggle = () => {
  const [showNotifications, setShowNotifications] = useState(false)
  const [notifications, setNotifications] = useState([])

  const loadingRef = useRef(true)

  const { currentUser } = useSelector(state => state.auth)
  const { unread_notifications_count } = currentUser

  const dispatch = useDispatch()

  const handleToggleClick = () => {
    if (!showNotifications) {
      getNotifications({ per_page: 5 })
        .then(res => {
          setNotifications(res.data)
          if (currentUser.unread_notifications_count !== 0) {
            updateNotificationsAsRead().catch(error => console.error(error))
            dispatch(
              setCurrentUser({ ...currentUser, unread_notifications_count: 0 })
            )
          }
          loadingRef.current = false
        })
        .catch(error => console.error(error))
      setShowNotifications(true)
    }
  }

  const handlePopoverClose = () => {
    setShowNotifications(false)
  }

  const popover = props => (
    <Popover id="popover-basic" {...props} className={styles.popover}>
      <UserNotifications
        hideNotifications={() => setShowNotifications(false)}
        notifications={notifications}
        loading={loadingRef.current}
      />
    </Popover>
  )

  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={popover}
        onExit={handlePopoverClose}
      >
        <div
          className={classNames(styles.wrapper, styles.wrapper__dropdown, {
            [styles.wrapper__active]: showNotifications,
          })}
          onClick={handleToggleClick}
        >
          <Bell height="24" width="24" />
          {unread_notifications_count > 0 && (
            <div className={styles.count}>{unread_notifications_count}</div>
          )}
        </div>
      </OverlayTrigger>
    </>
  )
}

export default NotificationsToggle
