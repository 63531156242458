import { useState } from "react"
import { rolesEnumerated } from "utils/common"
import { countryMap, countryRoles, codeArray } from "utils/countries"

import MenuItems from "./MenuItems"

import styles from "./desktopNavbar.module.scss"

const DesktopNavbar = () => {
  // const [industries, setIndustries] = useState([])
  const DEPTH_LEVEL = 0
  const [submenuItems, setSubMenuItems] = useState(0)

  const menuItems = [
    {
      title: "Companies",
      slug: "/companies",
      topLevel: true,
      submenu: [
        {
          title: "Explore Companies",
          slug: "/companies",
        },
        {
          title: "Rankings",
          slug: "/rankings",
        },
        {
          title: "Compare Companies",
          slug: "/companies/compare/Salesforce-vs-Snowflake",
          isNew: false,
        },
        {
          title: "Quarterly Report",
          slug: "/cloud-index/2024/Q2",
          isNew: true,
        },
        // {
        //   title: "Companies By Industry",
        //   slug: "/companies",
        //   submenu: industries.map(industry => ({
        //     title: industry.name,
        //     slug: `/companies/industries/${industry.slug}`,
        //   })),
        // },
      ],
    },
    {
      title: "Sales Jobs",
      slug: "/sales-jobs",
      label: "JOBS BY CATEGORY",
      topLevel: true,
      submenu: [
        {
          title: "SDR / BDR",
          slug: "/sales-jobs/by-category/sdr-bdr",
        },
        {
          title: "Account Executive",
          slug: "/sales-jobs/by-category/account-executive",
        },
        {
          title: "Account Manager",
          slug: "/sales-jobs/by-category/account-manager",
        },
        {
          title: "Customer Success",
          slug: "/sales-jobs/by-category/customer-success",
        },
        {
          title: "Sales Engineer",
          slug: "/sales-jobs/by-category/sales-engineer",
        },
        {
          title: "Remote Sales Jobs",
          slug: "/sales-jobs/by-category/remote",
        },
      ],
    },
    {
      title: "Salaries",
      slug: "/salaries",
      topLevel: true,
      submenu: [
        {
          title: "Medical Sales Salaries",
          slug: "/salaries/medical-device-sales-representative",
          isNew: true,
          submenu: [
            {
              title: "Medical Device Sales Representative",
              slug: "/salaries/medical-device-sales-representative",
            },
          ],
        },
        {
          title: "Salaries by Job Role",
          slug: "/salaries",

          submenu: [
            {
              title: "Account Executive",
              slug: "/salaries/account-executive",

              submenu: [
                {
                  title: "Strategic Account Executive",
                  slug: "/salaries/strategic-account-executive",
                },
                {
                  title: "Enterprise Account Executive",
                  slug: "/salaries/enterprise-account-executive",
                },
                {
                  title: "Mid Market Account Executive",
                  slug: "/salaries/mid-market-account-executive",
                },
                {
                  title: "SLED Account Executive",
                  slug: "/salaries/sled-account-executive",
                },
                {
                  title: "SMB Account Executive",
                  slug: "/salaries/smb-account-executive",
                },
              ],
            },
            {
              title: "Sales Management",
              slug: "/salaries/sales-manager",
              submenu: [
                {
                  title: "Sales Manager",
                  slug: "/salaries/sales-manager",
                },
                {
                  title: "Sales Development Manager",
                  slug: "/salaries/sales-development-manager",
                },
              ],
            },
            {
              title: "Account Manager",
              slug: "/salaries/account-manager",
            },
            {
              title: "Customer Success",
              slug: "/salaries/customer-success-manager",
            },
            {
              title: "Sales Development Representative",
              slug: "/salaries/sales-development-representative",
            },
            {
              title: "Sales Engineer",
              slug: "/salaries/sales-engineer",
            },
          ],
        },
        {
          title: "AE Salaries by City",
          slug: "/salaries",
          submenu: [
            {
              title: "Atlanta, GA",
              route: "salaries/",
              slug: "/salaries/account-executive/atlanta-ga",
            },
            {
              title: "Austin, TX",
              route: "salaries/",
              slug: "/salaries/account-executive/austin-round-rock-tx",
            },
            {
              title: "Boston, MA",
              route: "salaries/",
              slug: "/salaries/account-executive/boston-providence-ma-ri-nh-ct",
            },
            {
              title: "Chicago, IL",
              route: "salaries/",
              slug: "/salaries/account-executive/chicago-il",
            },
            {
              title: "Los Angeles, CA",
              route: "salaries/",
              slug: "/salaries/account-executive/los-angeles-anaheim-ca",
            },
            {
              title: "San Francisco, CA",
              route: "salaries/",
              slug: "/salaries/account-executive/san-francisco-oakland-ca",
            },
            {
              title: "San Jose, CA",
              route: "salaries/",
              slug: "/salaries/account-executive/san-jose-silicon-valley-ca",
            },
            {
              title: "New York, NY",
              route: "salaries/",
              slug: "/salaries/account-executive/greater-new-york-ny-nj-ct",
            },
            {
              title: "Seattle, WA",
              route: "salaries/",
              slug: "/salaries/account-executive/seattle-tacoma-wa",
            },
          ],
        },
        {
          title: "SDR Salaries by City",
          slug: "/salaries",
          submenu: [
            {
              title: "Atlanta, GA",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/atlanta-ga",
            },
            {
              title: "Austin, TX",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/austin-round-rock-tx",
            },
            {
              title: "Boston, MA",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/boston-providence-ma-ri-nh-ct",
            },
            {
              title: "Chicago, IL",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/chicago-il",
            },
            {
              title: "Los Angeles, CA",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/los-angeles-anaheim-ca",
            },
            {
              title: "San Francisco, CA",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/san-francisco-oakland-ca",
            },
            {
              title: "San Jose, CA",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/san-jose-silicon-valley-ca",
            },
            {
              title: "New York, NY",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/greater-new-york-ny-nj-ct",
            },
            {
              title: "Seattle, WA",
              route: "salaries/",
              slug: "/salaries/sales-development-representative/seattle-tacoma-wa",
            },
          ],
        },
        {
          title: "International Salaries",
          slug: "/salaries",
          submenu: codeArray.map(countryCode => {
            if (countryCode === "US") return null
            return {
              title: `${countryMap[countryCode]?.name}`,
              slug: "/salaries",
              submenu: countryRoles?.[countryCode]?.map(role => ({
                title: `${rolesEnumerated[role]}`,
                route: "salaries/",
                slug: `/salaries/${role}/${countryCode}`,
              })),
            }
          }),
        },
        {
          title: "Salary Calculator",
          slug: "/salary-calculator",
        },
      ],
    },
    {
      title: "Community",
      slug: "/community-insights",
      beta: true,
      topLevel: true,
    },
    {
      title: "For Employers",
      slug: "/employers",
      topLevel: true,
    },
  ]

  // useEffect(() => {
  //   getIndustries()
  //     .then(res => setIndustries(res.data))
  //     .catch(error => console.error(error))
  // }, [])

  return (
    <nav className={styles.navBar}>
      <ul className={styles.navBar__menus}>
        {menuItems.map((menu, index) => (
          <MenuItems
            submenuItems={submenuItems}
            setSubMenuItems={setSubMenuItems}
            items={menu}
            index={index}
            key={index}
            depthLevel={DEPTH_LEVEL}
            topLevel={menu.topLevel}
          />
        ))}
      </ul>
    </nav>
  )
}

export default DesktopNavbar
