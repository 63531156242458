import apiCall from "utils/api"
import { setCurrentUserFromProfile } from "reducers/auth"
import { identifyUser } from "utils/analytics"

export const getUserQAData = (questionIds, answerIds) => {
  const options = {
    params: {
      question_ids: questionIds,
      answer_ids: answerIds,
    },
  }
  return apiCall("get", `users/qa`, options)
    .then(response => response)
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getProfileFormOptions = () => {
  return apiCall("get", `users/profile_form_options`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const submitNewToSales = payload => {
  const options = { data: payload }
  identifyUser({ nts_type: payload.user.new_to_sales_type })

  return apiCall("post", `users/new_to_sales`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getProfile = () => async dispatch => {
  return apiCall("get", `users/profile`)
    .then(response => {
      const user = response.data
      dispatch(setCurrentUserFromProfile(user))
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateProfile = payload => async dispatch => {
  const options = { data: payload }
  return apiCall("put", `users/profile`, options)
    .then(response => {
      const user = response.data
      identifyUser(user.uuid, {
        first_name: user.first_name,
        last_name: user.last_name,
        linkedin_profile: user.linkedin_url,
        gender: user.gender,
        ethnicity: user.ethnicity,
      })
      dispatch(setCurrentUserFromProfile(user))
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const saveRankings = payload => {
  const options = { data: payload }

  return apiCall("post", `users/rankings`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCurrentOte = params => {
  const options = { params }
  return apiCall("get", `users/current_ote`, options)
    .then(response => response)
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getNotifications = params => {
  const options = { params }
  return apiCall("get", "users/notifications", options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateNotificationsAsRead = () => {
  return apiCall("post", "users/notifications/mark_read")
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateNotificationAsInteracted = id => {
  return apiCall("post", `users/notifications/${id}/mark_interacted`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getNotificationPreferences = () => {
  return apiCall("get", "users/notification_preferences")
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getNotificationPreferencesWithToken = token => {
  return apiCall("get", `/notification_preferences?token=${token}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateNotificationPreferences = payload => {
  const options = { data: payload }
  return apiCall("put", "users/notification_preferences", options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateNotificationPreferencesWithToken = (payload, token) => {
  const options = {
    data: {
      token: token,
      notification_preferences: payload,
    },
  }
  return apiCall("put", "/notification_preferences", options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const howYouHearAboutUs = (payload, surveyToken) => {
  const options = { data: payload }
  return apiCall(
    "put",
    `users/${surveyToken}/how_did_you_hear_about_us`,
    options
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const requestAccess = payload => {
  const options = { data: payload }
  return apiCall("patch", `users/unemployed`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateEmail = payload => {
  const options = { data: payload }
  return apiCall("patch", `users/update_email`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getWatchlist = () => {
  return apiCall("get", `users/companies/watchlist`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
